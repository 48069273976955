import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";

const SealCharts = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const API_URL = "https://nft.rolandstudios.com/fetch_sealstats.php"; 

  useEffect(() => {
    async function fetchLeaderboard() {
      try {
        const response = await axios.get(API_URL);
        console.log("API Response:", response.data);

        if (response.data.leaderboard && response.data.leaderboard.entries) {
          setLeaderboard(response.data.leaderboard.entries);
        } else {
          console.warn("No leaderboard entries found in API response.");
        }
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    }

    fetchLeaderboard();
  }, []);

  const maxPage = Math.ceil(leaderboard.length / pageSize) - 1;

  const handlePageChange = (newPage) => {
    setPage(Math.max(0, Math.min(newPage, maxPage)));
  };

  return (
    <BackgroundContainer> 
      <ChartWrapper>
        <h1>SEAL CHARTS</h1>
        <ChartPage>
          <StyledButton onClick={() => handlePageChange(page - 1)} disabled={page === 0}>
            &lt;&lt;
          </StyledButton>
          <p>[Page: {page + 1} of {maxPage + 1}]</p>
          <StyledButton onClick={() => handlePageChange(page + 1)} disabled={page === maxPage}>
            &gt;&gt;
          </StyledButton>
        </ChartPage>

        <TableWrapper>
          <StyledTable>
            <thead>
              <TableHeaderRow>
                <StyledTh>Rank</StyledTh>
                <StyledTh>Player</StyledTh>  
                <StyledTh>Elo</StyledTh>
              </TableHeaderRow>
            </thead>
            <tbody>
              {leaderboard.slice(page * pageSize, (page + 1) * pageSize).map((entry, index) => (
                <StaggeredRow key={index} delay={index * 0.1} even={index % 2 === 0}>
                  <StyledTd>{entry.rank}</StyledTd>
                  <StyledTd>
                    <PlayerContainer>
                      {entry.avatar && <Avatar src={entry.avatar} alt="Avatar" />}
                      <PlayerName>{entry.name}</PlayerName>
                    </PlayerContainer>
                  </StyledTd>
                  <StyledTd>{entry.score}</StyledTd>
                </StaggeredRow>
              ))}
            </tbody>
          </StyledTable>
        </TableWrapper>
      </ChartWrapper>
    </BackgroundContainer>
  );
};

// 🔥 Animated Background
const BackgroundAnimation = keyframes`
  from { background-position: 0 0; }
  to { background-position: -600px -600px; }
`;

const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background: url("/images/checkertile.png") repeat;
  animation: ${BackgroundAnimation} 8s linear infinite;
  display: flex;
  align-items: flex-end;  
  justify-content: center;
  padding-bottom: 100px;
`;

const ChartWrapper = styled.div`
  position: relative;
  width: 75%;
  max-width: 900px;
  padding: 40px;
  border-radius: 20px;
  background: rgba(42, 151, 205, 0.9);
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.7);
  text-align: center;

  h1 {
    color: rgb(255, 249, 87);
    font-size: 50px;
    font-weight: bold;
    font-family: "Comic Sans MS", cursive, sans-serif;
    text-shadow: 4px 4px 8px rgba(0, 0, 0, 0.6);
  }
`;

const ChartPage = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-bottom: 16px;

  p {
    font-family: "Comic Sans MS", cursive, sans-serif;
    font-size: 18px;
    color: white;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.75);
  }
`;

const StyledButton = styled.button`
  padding: 8px 12px;
  font-family: "Comic Sans MS", cursive, sans-serif;
  color: yellow;
  background: rgba(106, 183, 222, 0.9);
  border-radius: 8px;
  border: none;
  box-shadow: 4px 4px 4px rgba(20,20,20,.5);
  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.7);
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TableWrapper = styled.div`
  overflow: hidden;
  border-radius: 20px;
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
`;

const StyledTh = styled.th`
  color: yellow;
  padding: 16px;
  font-size: 22px;
  text-align: left;
  font-family: "Comic Sans MS", cursive, sans-serif;
  background-color: rgb(89, 183, 220);
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.6);
`;

const TableHeaderRow = styled.tr`
  background-color: rgb(125, 125, 125);
  font-weight: bold;
  color: yellow;

  th:first-child { border-top-left-radius: 20px; }
  th:last-child { border-top-right-radius: 20px; }
`;

const StaggeredRow = styled.tr`
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-out forwards;
  animation-delay: ${(props) => props.delay}s;
  
  background-color: ${(props) => (props.even ? "#A0D8F0" : "#78C7E0")};

  @keyframes fadeInUp {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;

// 🔥 Ensure RANK, PLAYER, and SCORE use Comic Sans MS
const StyledTd = styled.td`
  padding: 12px;
  text-align: left;
  font-family: "Comic Sans MS", cursive, sans-serif;
  font-size: 18px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
`;

const PlayerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const PlayerName = styled.span`
  font-family: "Comic Sans MS", cursive, sans-serif;
  font-size: 18px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
`;

const Avatar = styled.img`
  width: 32px; 
  height: 32px;
  border-radius: 50%;
  border: 2px solid yellow;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.5);
`;

export default SealCharts;
