

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/Header";
import "./App.css";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import DemonBlaster from "./components/DemonBlaster";
import Daydreamer from "./components/Daydreamer";
import Daydreamer2 from "./components/Daydreamer2";
import FlipperFrenzy from "./components/FlipperFrenzy";
import Store from "./components/Store";
import Slogs from "./components/Slogs";
import SealCharts from "./components/SealCharts"; 
import DaydreamerCharts from "./components/DaydreamerCharts"; 
import GetSlogsData from "./components/GetSlogData";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="App">
    <Helmet>
     
        <title>RolandStudios</title>
        <meta name="description" content="Experience the world of the Daydreamer Series and blast your way through the metaverse with Slogs, all created by solo indie developer Roland Studios. Join the adventure!" />
        <meta name = "keywords" content = "Indie game developer, 2D action games, 3D action games, Solo developer, Indie game studio, Action-adventure, Hand-drawn graphics, Retro-style gameplay, Indie game community, Game development resources, Game development community, Game development tips, Game development tutorials, Game design, Game development software, Game development tools, metaverse, blockchain, gaming, solana, NFT, action RPG, staking, biosynthetic mutant monsters, digital collectibles, decentralized, live beta" />
        
        <meta property="og:title" content="RolandStudios | Solo Indie Game developer" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.rolandstudios.com" />
        <meta property="og:image" content="https://www.rolandstudios/images/Logo.png" />
        <meta property="og:description" content="Experience the immersive world of the Daydreamer Series and blast your way through the metaverse with Demon Blaster VR, all created by solo indie developer Roland Studios. Join the adventure now!" />

        <meta name="twitter:card" content="Experience the immersive world of the Daydreamer Series and blast your way through the metaverse with Demon Blaster VR, all created by solo indie developer Roland Studios. Join the adventure now!" />
        <meta name="twitter:site" content="@roland_studios" />
        <meta name="twitter:title" content="RolandStudios | Solo Indie Game developer" />
        <link rel="canonical" href ="/" />
      
    </Helmet>
   
      <Router>
       { <Header /> }
        <Switch>
          
          <Route exact path="/">
            <Home />
          </Route>

          <Route path="/daydreamer">
            <Daydreamer />
          </Route>

          <Route path="/daydreamer2">
            <Daydreamer2 />
          </Route>

          <Route path="/slogs">
            <Slogs />
          </Route>

          <Route path="/flipperfrenzy">
            <FlipperFrenzy />
          </Route>

          <Route path="/slogscharts">
            <GetSlogsData />
          </Route>

          <Route path="/demonblaster">
            <DemonBlaster />
          </Route>

          <Route path="/store">
            <Store />
          </Route>
     
          <Route path="/about">
            <About />
          </Route>

          <Route path="/contact">
            <Contact />
          </Route>

          <Route path="/sealcharts">
            <SealCharts />
          </Route>
         
          <Route path="/daydreamercharts">
            <DaydreamerCharts />
          </Route>

        </Switch>  
      </Router>
      <Footer />
    </div>  
  );
}

export default App;
