import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import axios from "axios";

const SealCharts = () => {
  const [leaderboard, setLeaderboard] = useState([]);
  const [page, setPage] = useState(0);
  const pageSize = 10;  // ✅ Max 10 entries per page
  const API_URL = "https://nft.rolandstudios.com/fetch_daydreamerstats.php"; 

  useEffect(() => {
    async function fetchLeaderboard() {
      try {
        const response = await axios.get(API_URL);
        console.log("API Response:", response.data);

        if (response.data.leaderboard && response.data.leaderboard.entries) {
          setLeaderboard(response.data.leaderboard.entries);
        } else {
          console.warn("No leaderboard entries found in API response.");
        }
      } catch (error) {
        console.error("Error fetching leaderboard data:", error);
      }
    }

    fetchLeaderboard();
  }, []);

  const maxPage = Math.ceil(leaderboard.length / pageSize) - 1;

  const handlePageChange = (newPage) => {
    setPage(Math.max(0, Math.min(newPage, maxPage)));
  };

  return (
    <BackgroundContainer> 
      <ChartWrapper>
        <h1>Daydreamer Charts</h1>
        <ChartPage>
          <button onClick={() => handlePageChange(page - 1)} disabled={page === 0}>
            &lt;&lt;
          </button>
          <p>[Page: {page + 1} of {maxPage + 1}]</p>
          <button onClick={() => handlePageChange(page + 1)} disabled={page === maxPage}>
            &gt;&gt;
          </button>
        </ChartPage>

        <TableWrapper>
          <StyledTable>
            <thead>
              <TableHeaderRow>
                <th>Rank</th>
                <th>Player</th>  
                <th>Score</th>
              </TableHeaderRow>
            </thead>
            <tbody>
              {leaderboard.slice(page * pageSize, (page + 1) * pageSize).map((entry, index) => (
                <StaggeredRow key={index} delay={index * 0.1}>
                  <td>{entry.rank}</td>
                  <td>
                    <PlayerContainer>
                      {entry.avatar && <Avatar src={entry.avatar} alt="Avatar" />}
                      {entry.name}
                    </PlayerContainer>
                  </td>
                  <td>{entry.score}</td>
                </StaggeredRow>
              ))}
            </tbody>
          </StyledTable>
        </TableWrapper>
      </ChartWrapper>
    </BackgroundContainer>
  );
};

// Styled Components
const BackgroundAnimation = keyframes`
  from { background-position: 0 0; }
  to { background-position: -600px -600px; }
`;

const BackgroundContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100vh;
  background: url("/images/checkertile.png") repeat;
  animation: ${BackgroundAnimation} 8s linear infinite;
  display: flex;
  align-items: flex-end;  
  justify-content: center;
  padding-bottom: 100px;
`;

const ChartWrapper = styled.div`
  position: relative;
  width: 75%;  // ✅ Only 25% wider instead of 50%
  max-width: 900px;
  padding: 40px;
  border-radius: 20px;  // ✅ Rounds only the outside of the chart
  background: rgba(30, 30, 30, 0.9);
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.7);
  text-align: center;

  h1 {
    color: yellow;
    font-size: 50px;
    font-weight: 900;
    text-shadow: 4px 4px 8px rgb(0 0 0 / 0.5);
  }
`;

const ChartPage = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  margin-bottom: 16px;

  button {
    padding: 8px;
    color: yellow;
    background-color: gray;
    border-radius: 8px;
    box-shadow: 4px 4px 4px rgba(20,20,20,.5);
  }
`;

const TableWrapper = styled.div`
  overflow: hidden;
  border-radius: 20px;  // ✅ Rounds only the table wrapper, not the rows
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 0; // ✅ Ensures no row gets rounded

  th {
    color: yellow;
    padding: 16px;
    font-size: 20px;
    text-align: left;
    background-color: rgb(125,125,125);
  }

  td {
    padding: 12px;
    text-align: left;
  }

  tr:nth-child(odd) {
    background-color: rgb(77,77,77);
  }

  tr:nth-child(even) {
    background-color: rgb(55,55,55);
  }
`;

const TableHeaderRow = styled.tr`
  background-color: rgb(125,125,125);
  font-weight: bold;
  color: yellow;

  th:first-child { border-top-left-radius: 20px; }  // ✅ Rounds top-left corner
  th:last-child { border-top-right-radius: 20px; } // ✅ Rounds top-right corner
`;

const StaggeredRow = styled.tr`
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-out forwards;
  animation-delay: ${(props) => props.delay}s;

  @keyframes fadeInUp {
    0% { opacity: 0; transform: translateY(20px); }
    100% { opacity: 1; transform: translateY(0); }
  }
`;

const PlayerContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const Avatar = styled.img`
  width: 32px; 
  height: 32px;
  border-radius: 50%;
  border: 2px solid yellow;
`;

export default SealCharts;
